<template>
  <div class="info-list">
    <ul>
      <li>车辆自编号：{{ item.bindKey }}</li>
      <li>车牌号：{{ item.bindName }}</li>
      <li>速度：{{ speed }}KM/h</li>
      <li>方向：{{ item.direction }}°</li>
      <li>最近一次点位上报：{{ item.deviceTime }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    lastUpdateTime() {
      return ''
    },
    onlineStatus() {
      return ''
    },
    speed() {
      const speed = this.item.speed
      if (speed) {
        return (Number(speed) / 10).toFixed(2)
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.info-list {
  //   margin-left: 110px;
  margin-top: -5px;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.info-list ul li {
  height: 24px;
  line-height: 24px;
}
</style>
