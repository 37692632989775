import { render, staticRenderFns } from "./vehicle-info-list.vue?vue&type=template&id=51b72191&scoped=true&"
import script from "./vehicle-info-list.vue?vue&type=script&lang=js&"
export * from "./vehicle-info-list.vue?vue&type=script&lang=js&"
import style0 from "./vehicle-info-list.vue?vue&type=style&index=0&id=51b72191&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b72191",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51b72191')) {
      api.createRecord('51b72191', component.options)
    } else {
      api.reload('51b72191', component.options)
    }
    module.hot.accept("./vehicle-info-list.vue?vue&type=template&id=51b72191&scoped=true&", function () {
      api.rerender('51b72191', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/cleaner/dark/vehicle-info-list.vue"
export default component.exports